<template>
  <div v-if="placeholder" class="placeholder-package-item"></div>
  <button v-else class="package-item">
    <span class="title">{{ packageTitle }}</span>
    <span class="subtitle">{{ packageSubtitle }}</span>
    <img class="package-image" :src="image" :alt="packageTitle" />
  </button>
</template>

<script>
export default {
  props: {
    packageTitle: String,
    packageSubtitle: String,
    image: String,
    placeholder: Boolean,
  },
};
</script>

<style lang="scss">
:root {
  --package-item-height: 125px;
}

.placeholder-package-item {
  background-color: var(--bg-color-2);
  min-height: var(--package-item-height);
}

.package-item {
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  text-align: left;
  min-height: var(--package-item-height);
  position: relative;
  padding: 8px;
  background-color: var(--primary-color);
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--accent-color-1);
  }

  .title,
  .subtitle {
    font-family: var(--font-family-heading);
    font-size: 19px;
    letter-spacing: 0.4px;
    line-height: 21px;
  }

  .title {
    color: #fff;
  }

  .subtitle {
    color: #000;
  }

  .package-image {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
